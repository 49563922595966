@tailwind base;
@tailwind components;
@tailwind utilities;

h4 {
  @apply text-lg md:text-xl font-medium;
}

.Root {
  isolation: isolate;
}

